import type { Profile } from '~/models';

import { useNuxtApp } from '#app';

const useProfileStore = defineStore('customer-profile', () => {
  const { $http } = useNuxtApp();

  const getByUsername = (username: string): Promise<Profile | null> => {
    return $http.profile.getByUsername(username);
  };

  const update = (profile: Profile): Promise<Profile | null> => {
    return $http.profile.put(profile);
  };

  return {
    getByUsername,
    update
  };
});

export { useProfileStore };
